.Content {
  margin: auto;
  max-width: 700px;
}

.Content strong {
  font-weight: 600;
}

.Content p {
  margin: 20px 0;
  text-align: justify;
  text-indent: 25px;
}

.Content h2 {
  border-left: 10px solid #feed00;
  line-height: 24px;
  margin: 55px 0 30px;
  padding: 4px 8px;
  text-transform: uppercase;
}

.Content ul {
  list-style: none;
}

.Content ul:last-of-type strong {
  font-weight: 400;
}

@media (max-width: 1023px) {
  .Content {
    order: 2;
  }

  .Content h2 {
    margin: 40px 0 30px;
  }

  .Content ul {
    padding: 0 25px;
  }
}

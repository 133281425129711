.SignIt {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
  margin-bottom: 40px;
  padding: 30px;
}

.SignIt input + div,
.SignIt-error {
  color: #ea2f48;
  font-size: 0.9em;
  font-style: italic;
  font-weight: 600;
  margin-top: 4px;
  text-align: center;
}

.SignIt-newsletter div {
  font-size: 0.85em;
  line-height: 1.4em;
  margin: 10px 0;
}

.SignIt-newsletter input {
  margin-right: 8px;
  width: auto;
}

.SignIt-newsletter label {
  display: inline;
  text-transform: none;
}

.SignIt-error {
  margin: 30px 0 10px;
}

.SignIt button {
  background: black;
  border-radius: 3px;
  color: #feed00;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  width: 100%;
}

.SignIt button:hover,
.SignIt button:focus,
.SignIt button:disabled {
  background: #feed00;
  color: black;
  outline: 0;
}

@media (max-width: 1023px) {
  .SignIt {
    margin-bottom: 25px;
  }
}

.App-main {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1023px) {
  .App-main {
    flex-wrap: wrap;
  }
}

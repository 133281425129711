.Header {
  background: url("assets/banner.jpg") no-repeat center;
  background-size: cover;
  height: 440px;
  margin-bottom: 30px;
}

.Header .wrapper {
  align-items: center;
  display: flex;
  height: 100%;
}

.Header header {
  text-align: center;
  width: calc(100% - 380px);
}

.Header h1 {
  color: white;
  font-size: 3.3em;
  font-weight: 400;
  line-height: 1.1em;
  margin: 0 0 60px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
}

.Header h2 {
  background: #feed00;
  border-radius: 12px;
  color: black;
  display: inline-block;
  font-size: 2.5em;
  margin: 0;
  padding: 8px 8px 0;
  text-transform: uppercase;
}

@media (max-width: 1279px) {
  .Header {
    height: 300px;
    margin-bottom: 20px;
  }

  .Header header {
    width: calc(100% - 310px);
  }

  .Header h1 {
    font-size: 2.2em;
    margin-bottom: 40px;
  }

  .Header h2 {
    font-size: 1.8em;
    padding-bottom: 2px;
  }
}

@media (max-width: 1023px) {
  .Header {
    height: auto;
    margin-bottom: 30px;
    padding: 30px 0;
  }

  .Header header {
    width: 100%;
  }

  .Header h1 {
    font-size: 1.5em;
    margin-bottom: 25px;
  }

  .Header h2 {
    font-size: 1.3em;
    padding: 7px 8px 2px;
  }
}

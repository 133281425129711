.Aside {
  margin-left: 80px;
  margin-top: -350px;
  min-width: 300px;
  position: sticky;
  top: 40px;
  width: 300px;
}

@media (max-width: 1279px) {
  .Aside {
    margin-left: 60px;
    margin-top: -220px;
    width: 250px;
  }
}

@media (max-width: 1023px) {
  .Aside {
    margin: 0 0 20px;
    order: 1;
    position: static;
    width: 100%;
  }

  .Aside:after {
    background: #333;
    content: "";
    display: block;
    height: 1px;
    margin: 40px auto 0;
    width: 80%;
  }
}

@media (max-height: 900px) {
  .Aside {
    top: 10px;
  }
}

@media (max-height: 850px) {
  .Aside {
    position: static;
  }
}

@keyframes appear {
  from {
    transform: translateY(-80px);
  }
}

.Modal {
  animation: appear 0.6s;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 40px auto;
  pointer-events: auto;
  width: 768px;
}

.Modal h1 {
  background: black;
  color: #feed00;
  margin: 0;
  min-height: 80px;
  padding: 20px 75px 20px 20px;
  position: relative;
  text-transform: uppercase;
}

.Modal button {
  color: white;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: 0.2s;
}

.Modal button:focus,
.Modal button:hover {
  color: #ea2f48;
  outline: 0;
}

.Modal h1 + div {
  padding: 20px 40px;
}

@media (max-width: 1023px) {
  .Modal {
    margin: 20px auto;
    width: calc(100% - 20px);
  }

  .Modal h1 {
    font-size: 1.2em;
    padding-bottom: 18px;
    min-height: 60px;
  }

  .Modal h1 + div {
    font-size: 0.9em;
    padding: 10px 20px;
  }
}

.Modal .Modal-share {
  border-top: 2px solid #333;
  margin: 25px auto 20px;
  max-width: 300px;
  padding-top: 30px;
  text-align: center;
}

.Modal .Modal-share .Share h2 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.Modal .Modal-share .Share a {
  height: 35px;
  line-height: 38px;
  width: 35px;
}

.Modal .Modal-share .Share-whatsapp {
  font-size: 1.1em !important;
  line-height: 34px !important;
}

.Counter {
  background: black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #feed00;
  font-size: 3em;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.Validation-loading {
  background: black;
  border-radius: 50%;
  color: #feed00;
  display: block;
  font-size: 1.5em;
  height: 40px;
  line-height: 45px;
  margin: 20px auto;
  text-align: center;
  width: 40px;
}

.Validation-share {
  border-top: 2px solid #333;
  margin: 20px auto;
  max-width: 300px;
  padding-top: 30px;
  text-align: center;
}

.Validation-share .Share h2 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.Validation-share .Share a {
  height: 35px;
  line-height: 38px;
  width: 35px;
}

.Validation-share .Share-whatsapp {
  font-size: 1.1em !important;
  line-height: 34px !important;
}

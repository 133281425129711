.Nav {
  background: black;
  display: flex;
  height: 35px;
  justify-content: space-between;
  padding: 10px 15px;
}

.Nav a,
.Nav button {
  color: white;
}

.Nav-logo img {
  height: 15px;
}

.Nav div a,
.Nav div button {
  font-size: 0.85em;
  margin-left: 12px;
  transition: 0.2s;
}

.Nav div a:hover,
.Nav div button:hover,
.Nav div a:focus,
.Nav div button:focus {
  color: #feed00;
  outline: 0;
}

.Nav-about h2 {
  margin-top: 40px;
  text-transform: uppercase;
}

.Nav-about p {
  line-height: 1.4em;
}

.Nav-about a {
  color: black;
  font-weight: 600;
}

/* @font-face {
  font-family: "Geomanist";
  src: url("fonts/Geomanist-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
} */

@font-face {
  font-family: "Geomanist";
  src: url("fonts/Geomanist-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src: url("fonts/Geomanist-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src: url("fonts/Geomanist-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  background: #f0f0f0;
  color: #262626;
  font-family: Geomanist, sans-serif;
  padding-bottom: 120px;
}

fieldset {
  border: 0;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}

fieldset:focus-within label {
  text-indent: 20px;
}

label {
  display: block;
  margin-bottom: 4px;
  text-indent: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

label span {
  color: #ffcc00;
  font-weight: 600;
}

input {
  border: 1px solid #000;
  border-radius: 3px;
  border-top-right-radius: 10px;
  color: #262626;
  line-height: 18px;
  padding: 10px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
}

input:hover,
input:focus {
  border-color: #ffcc00;
  outline: 0;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
}

button i {
  margin-right: 4px;
}

.wrapper {
  margin: auto;
  padding: 0px 40px;
  width: 1280px;
}

@media (max-width: 1279px) {
  .wrapper {
    width: 1024px;
  }
}

@media (max-width: 1023px) {
  body {
    font-size: 0.9em;
  }

  .wrapper {
    padding: 0 20px;
    width: 100%;
  }
}
